<template>
    <v-card outlined raised>

        <v-tooltip bottom v-if="tooltip">
            <template v-slot:activator="{ on }">
                <v-row align="center" justify="center" v-on="on">
                    <p class="mt-4">{{ heading }}</p>
                </v-row>
                <v-row align="center" justify="center" v-on="on">
                    <transition mode="out-in" name="slide-fade">
                        <p :key="score" class="headline">{{score}}</p>
                    </transition>
                </v-row>
            </template>
            <span>{{tooltip}}</span>
        </v-tooltip>
        <div v-else>
            <v-row align="center" justify="center">
              <p class="mt-4">{{ heading }}</p>
            </v-row>
            <v-row align="center" justify="center">
                <transition mode="out-in" name="slide-fade">
                    <p :key="score" class="headline">{{score}}</p>
                </transition>
            </v-row>
        </div>
    </v-card>
</template>
<script>
    export default {
        name: 'RapidfireTextDisplay',
        props: {
            heading: {},
            score: {},
            tooltip: {}

        }
    }
</script>
<!--suppress CssUnusedSymbol -->
<style scoped>
    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        color: red;
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for <2.1.8 */
    {
        /*transform: translateX(10px);*/

        opacity: 0;
    }
</style>
