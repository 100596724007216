<template>
  <v-container v-cloak fluid grid-list-lg>
    <div>
      <v-alert
          v-model="alert"
          border="left"
          color="green"
          colored-border
          dismissible
          elevation="2"
          icon="mdi-check"
      >
        You answered {{ heading.cas }} correctly. Your score has been saved!.
      </v-alert>
    </div>
    <v-layout row wrap>


      <v-flex sm9 xs12>
        <v-card v-cloak outlined raised width="100%">
          <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
            <v-toolbar-title>Rapidfire</v-toolbar-title>
          </v-toolbar>

          <v-progress-linear v-show="showProgress" :indeterminate="true"/>

          <!--                    <v-card-title primary-title width="100%">-->
          <!--                        <div style="width:100%">-->
          <!--                            <div class="headline indigo&#45;&#45;text">-->
          <!--                                Rapidfire-->
          <!--                                <hr class="red darken-1"/>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </v-card-title>-->
          <v-card-text v-if="Object.keys(mcq).length > 0" class="overflow-y-auto">
            <p class="title text--primary"
               v-html="mcq.q"/>
            <v-radio-group v-model="radios" :disabled="disable" :mandatory="false" class="radio-group-full-width"
                           column>


              <v-radio
                  v-for="(option, index) in mcq.o"
                  :key="index"
                  :class="getBorderColor(radios, mcq.c, index)"
                  :label="option"
                  :value="index"
                  class="ma-2"
                  style="margin:3px; height: auto;"
              >
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-text v-else>
            <div class="text-center">
              <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
              />
            </div>
          </v-card-text>
          <v-card-actions v-if="showNext">
            <v-flex xs12>
              <v-btn v-if="showNext" color="pink darken-1 white--text" @click="NextQuestionPlease">
                NEXT
                <v-icon dark right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-card-actions>
          <v-card-actions v-if="goHome">
            <v-flex xs12>
              <v-btn color="red darken-1 white--text" @click="$router.push('/')">
                Home
                <v-icon dark right>mdi-home</v-icon>
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex sm3 xs12>
        <RapidfireTextDisplay :heading="heading.gh" :score="heading.ghs" :tooltip="heading.ghsN"/>
        <RapidfireTextDisplay :heading="heading.yh" :score="heading.yhs"/>
        <RapidfireTextDisplay :heading="heading.ca" :score="heading.cas"/>
        <RapidfireTextDisplay :heading="heading.wl" :score="heading.wls"/>

      </v-flex>

    </v-layout>


  </v-container>
</template>

<script>
// import RapidFireComponent from "../components/RapidFireComponent";
import RapidfireTextDisplay from "../components/RapidfireTextDisplay";
import {APIService} from "../APIService";

const apiService = new APIService();

export default {
  metaInfo: {
    title: 'Rapidfire - Let the Games Begin',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
      }
    ]
  },
  name: "Rapidfire",
  components: {RapidfireTextDisplay},
  data: () => ({
    disable: false,
    alert: false,
    heading:
        {
          gh: "Global Highscore",
          ghs: '',
          ghsN: '',
          yh: "Your Highscore",
          yhs: '',
          ca: "Correct Answered",
          cas: 0,
          wl: "Wrong Left",
          wls: 3,

        },
    goHome: false,
    score: "14",
    radios: null,
    mcqs: {
      q: "Question will come here",
      o: ["OptionA", "OptionB", "OptionC", "OptionD"],
      c: 1,

    },
    currentQuestion: 0,
    showNext: false,
    showProgress: true,
  }),
  mounted: function () {
    this.$nextTick(function () {
      this.$store.commit("setShowProgress", true);

      if (localStorage.getItem("rapidfireHighScore") !== null) {
        const data = localStorage.getItem("rapidfireHighScore");
        // eslint-disable-next-line no-console
        // console.log(data);
        this.heading.ghs = data.ghs;
        this.heading.yhs = data.yhs;
      }


      this.getHighScoreFromServer();
      // entire view has been rendered
      this.getQuestionFromServer();


    });
  },
  computed: {
    mcq: {
      get() {
        if (this.mcqs[this.currentQuestion] !== undefined) {
          return this.mcqs[this.currentQuestion]
        } else {
          return {};
        }
      },
      set(value) {
        return value;
      }
    },

  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    radios(newCount) {
      if (newCount != null) {
        newCount = parseInt(newCount);

        if (newCount === this.mcq.c) {
          this.heading.cas++;

        } else {
          this.heading.wls--;

        }
        this.shouldShowNext();
      }
    }
  },
  methods: {
    getHighScoreFromServer() {
      this.$store.commit("setShowProgress", true);

      const app = this;
      app.showProgress = true;

      apiService.getRapidfireHighscore(app.heading.cas).then(data => {

        // eslint-disable-next-line no-console
        // console.log(data.h);
        localStorage.setItem("rapidfireHighScore", JSON.stringify(data.h));
        app.heading.ghs = data.h.ghs;
        app.heading.ghsN = data.h.ghsN;
        app.heading.yhs = data.h.yhs;
        app.showProgress = false;
        app.$store.commit("setShowProgress", false);

      });
    },
    shouldShowNext() {
      this.goHome = this.heading.wls <= 0;
      if (this.goHome === true) {
        const app = this;
        app.showProgress = true;

        apiService.saveRapidfire(app.heading.cas).then(function () {

          app.showProgress = false;
          app.alert = true;
          app.disable = true;

          app.getHighScoreFromServer();
        });
      }
      this.showNext = this.heading.wls > 0;
      // this.NextQuestionPlease();
    },
    getQuestionFromServer() {
      const app = this;
      app.showProgress = true;
      app.radios = null;
      app.showNext = false;

      apiService.getRapidfireMCQFromServer().then(data => {
// eslint-disable-next-line no-console
//                     console.log(data.mcqs);

        // let c = [{
        //     q: "Hello there. I am a very long question with very long text to be shown.",
        //     o: ["Hello there. I am a very long question with very long text to be shown.", "Hello there. I am a very long question with very long text to be shown.", "Hello there. I am a very long question with very long text to be shong question with very long text to be shong question with very long text to be shong question with very long text to be shong question with very long text to be shong question with very long text to be shong question with very long text to be shown.", "Hello there. I am a very long question with very long text to be shown."],
        //     c: 2
        // }];
        //
        // app.mcqs = c;


        app.mcqs = data.mcqs;
        app.showProgress = false;
      });
    },
    NextQuestionPlease() {

      this.getQuestionFromServer();

      this.radios = null;
      this.showNext = false;

    },
    submit() {
      this.show = !this.show;
    },
    getBorderColor: function (ua, ca, index) {
      if (this.radios != null) {
        if (ua === ca && index === ca) {
          return "greenborder";
        } else if (index === ua) {
          return "redborder";
        } else if (index === ca) {
          return "greenborder";
        } else {
          return "";
        }
      }
    }
  },
  beforeDestroy: function () {
    this.heading = undefined;
    this.mcq = undefined;
    this.mcqs = undefined;
    this.radios = undefined;
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.greenborder {
  border: 1px solid green;
  /* padding: 2px; */
  margin: 2px;
}

.redborder {
  border: 1px solid red;
  /* padding: 2px; */
  margin: 2px;
}

.v-input__control {
  width: 100% !important
}

.v-label {
  width: 100% !important;


}

.radio-group-full-width >>> .v-input__control {
  width: 100%
}

>>> .v-label {
  width: 100% !important;
  height: auto !important;
}

.someclass {
  width: 100%;
  height: 3em;
  color: green;
}

label {
  height: auto;
}

[v-cloak] {
  display: none;
}
</style>
